import { HttpParams } from '@angular/common/http';
import { IShopOrderItem } from '@x/ecommerce/shop-client';
import { IGtmEvent, IGtmProduct } from '../models';
import { GtmEvent } from './gtm-event.event';

export interface IGtmCheckoutEvent {
  checkout: {
    actionField: {
      step: number;
    };
    products: IGtmProduct[];
  };
}

export class GtmCheckoutEvent extends GtmEvent {
  public ecommerce: IGtmCheckoutEvent;

  constructor(name: IGtmEvent, step: number) {
    super(name);
    this.ecommerce = { checkout: { actionField: { step }, products: [] } };
  }

  public buildEvent(items: IShopOrderItem[]): void {
    this.ecommerce.checkout.products = items.map((product) => this.convertToProduct(product));
  }

  public toEvent() {
    const baseData = super.toEvent();
    const pathParts = location.pathname.split('/');
    pathParts.splice(2, 1);

    const searchParams = new HttpParams({ fromString: location.search });
    const params = searchParams.delete('redirect').toString();

    return {
      ...baseData,
      pagePath: `${pathParts.join('/')}${params ? `?${params}` : ''}`,
      pageTitle: document.title,
      ecommerce: this.ecommerce,
    };
  }
}
