import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';
import { Observable, fromEvent } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CookieBotService {
  constructor(
    private windowRef: WindowRef,
    @Inject(SHOP_CONFIG) private shopConfig: IShopConfig,
  ) {}

  cookieConsent$: Observable<boolean> = fromEvent(
    this.windowRef.instance,
    'CookiebotOnAccept',
  ).pipe(
    map(() => !!this.windowRef.get('Cookiebot')?.consent),
    startWith(this.hasConsentedToCookies()),
    shareReplay(),
  );

  // return true on local
  hasConsentedToCookies(): boolean {
    return this.shopConfig.production ? this.windowRef.get('Cookiebot')?.consented ?? false : true;
  }
}
