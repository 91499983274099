import { IShopProductItem } from '@x/ecommerce/shop-client';
import { IGtmProduct } from '../models';
import { GtmEvent } from './gtm-event.event';

export interface IGtmImpressionEvent {
  ecommerce: { impressions: IGtmProduct[] };
}

export class GtmImpressionEvent extends GtmEvent implements IGtmImpressionEvent {
  public ecommerce: { impressions: IGtmProduct[] } = { impressions: [] };

  constructor() {
    super('EEImpression');
    this.ecommerce.impressions = [];
  }

  public buildEvent(
    products: IShopProductItem[],
    item_list_id: number | string,
    item_list_name?: string,
  ): void {
    this.ecommerce.impressions = products.map((product) => {
      const gtmProduct = this.convertToProduct(product);
      return { ...gtmProduct, item_list_name, item_list_id };
    });
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
