import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TFormOrigin, TrackingEventName } from '@x/common/tracking/tracking.types';

type TFormEventArgs<Data> = {
  formOrigin: TFormOrigin;
  data: Data;
};
export abstract class FormEvent<Data extends object> extends TrackingEvent<TFormEventArgs<Data>> {
  [TrackingEventName] = 'form';

  constructor(origin: TFormOrigin, data: Data) {
    super({ formOrigin: origin, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form', obj);
  }
}

type TFormAddressSearchData = {
  query: string;
  results: boolean;
};
export class TEFormAddressSearch extends FormEvent<TFormAddressSearchData> {
  [TrackingEventName] = 'form:address-search';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form:address-search', obj);
  }
}

type TFormAddressSearchSelectResultData = {
  result: string;
};
export class TEFormAddressSearchSelectResult extends FormEvent<TFormAddressSearchSelectResultData> {
  [TrackingEventName] = 'form:select-address-search-result';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form:select-address-search-result', obj);
  }
}

type TFormStepChangeData = {
  stepLabel: string;
};
export class TEFormStepChange extends FormEvent<TFormStepChangeData> {
  [TrackingEventName] = 'form:change-step';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form:change-step', obj);
  }
}

type TFormFieldUpdate = {
  fieldLabel: string;
  fieldValue: string;
};
export class TEFormFieldUpdate extends FormEvent<TFormFieldUpdate> {
  [TrackingEventName] = 'form:field-update';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form:field-update', obj);
  }
}

type TFormMapMovePin = {
  coordinates: string;
};
export class TEFormMapMovePin extends FormEvent<TFormMapMovePin> {
  [TrackingEventName] = 'form:map-move-pin';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('form:map-move-pin', obj);
  }
}
