import { IGtmEvent } from '../models';
import { GtmEvent } from './gtm-event.event';

export interface IGtmCheckoutActionEvent {
  checkout_option: {
    actionField: {
      step: number;
      option?: string;
    };
  };
}

export class GtmCheckoutActionEvent extends GtmEvent {
  public ecommerce: IGtmCheckoutActionEvent;

  constructor(name: IGtmEvent, step: number) {
    super(name);
    this.ecommerce = { checkout_option: { actionField: { step } } };
  }

  public buildEvent(option: string): void {
    this.ecommerce.checkout_option.actionField = {
      ...this.ecommerce.checkout_option.actionField,
      option,
    };
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
