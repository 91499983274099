import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';
import { IShopCheckoutOrder, IShopCurrency } from '@x/ecommerce/shop-client';

type TConversionEventArgs<Data> = {
  data: Data;
};

export abstract class ConversionEvent<Data extends object> extends TrackingEvent<
  TConversionEventArgs<Data>
> {
  [TrackingEventName] = 'conversion';

  constructor(data: Data) {
    super({ data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('conversion', obj);
  }
}

interface IConversionPurchaseData {
  order: IShopCheckoutOrder;
  payment: {
    id: number;
    amount: number;
    currency: string;
  };
}

export class TEConversionPurchase extends ConversionEvent<IConversionPurchaseData> {
  [TrackingEventName] = 'conversion:purchase';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('conversion:purchase', obj);
  }
}

interface IConversionSubscriptionData {
  subscriber: {
    id: number;
    price?: IShopCurrency | null;
  };
}

export class TEConversionSubscription extends ConversionEvent<IConversionSubscriptionData> {
  [TrackingEventName] = 'conversion:subscription';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('conversion:subscription', obj);
  }
}

interface IConversionMembershipData {
  membershipName: string;
}

export class TEConversionMembership extends ConversionEvent<IConversionMembershipData> {
  [TrackingEventName] = 'conversion:membership';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('conversion:membership', obj);
  }
}
