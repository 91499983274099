import { TrackingEvent } from '@x/common/tracking/event-models/event';
import {
  TAssociationOrigin,
  TrackingEventName,
  TShopRouteOrigin,
} from '@x/common/tracking/tracking.types';
import { IShopProductItem } from '@x/ecommerce/shop-client';

type TImpressionEventArgs<Data> = {
  routeOrigin: TShopRouteOrigin;
  data: Data;
};

export abstract class ImpressionEvent<Data extends object> extends TrackingEvent<
  TImpressionEventArgs<Data>
> {
  [TrackingEventName] = 'impression';

  constructor(routeOrigin: TShopRouteOrigin, data: Data) {
    super({ routeOrigin, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('impression', obj);
  }
}

export interface IImpressionProductData {
  product: IShopProductItem;
  listName?: string;
  taxonId?: number;
  associationId?: number;
  associationOrigin?: TAssociationOrigin;
}
export class TEImpressionProduct extends ImpressionEvent<IImpressionProductData> {
  [TrackingEventName] = 'impression:product';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('impression:product', obj);
  }
}
