import { GtmEvent } from './gtm-event.event';

export interface IGtmCheckoutPaymentEvent {
  checkout: {
    actionField: {
      step: number;
      option?: string;
    };
  };
}

export class GtmCheckoutPaymentEvent extends GtmEvent {
  public ecommerce: IGtmCheckoutPaymentEvent;

  constructor(step: number = 3) {
    super('EECheckoutPaymentMethod');
    this.ecommerce = { checkout: { actionField: { step } } };
  }

  public buildEvent(method: string): void {
    this.ecommerce.checkout.actionField.option = method;
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
