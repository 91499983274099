import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';
import { IShopProductSearchResult } from '@x/ecommerce/shop-client';

type TSearchEventArgs<Data> = {
  data: Data;
};
abstract class SearchEvent<Data> extends TrackingEvent<TSearchEventArgs<Data>> {
  [TrackingEventName] = 'search';

  constructor(data: Data) {
    super({ data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('search', obj);
  }
}

type TSearchResultsData = {
  query: string;
  result: IShopProductSearchResult;
};
export class TESearchResults extends SearchEvent<TSearchResultsData> {
  [TrackingEventName] = 'search:results';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('search:results', obj);
  }
}
