import { InjectionToken } from '@angular/core';
import { IAlgoliaProviderConfig } from '@x/common/tracking/providers/algolia/algolia.config';
import { IFireworksConfig } from '@x/common/tracking/providers/fireworks/fireworks.config';
import { IGtmConfig } from '@x/common/tracking/providers/google-tag-manager/gtm.config';
import { ILogRocketProviderConfig } from '@x/common/tracking/providers/log-rocket/log-rocket.config';
import { IShopAuthUser } from '@x/ecommerce/shop-client';
import { ICountryItem } from '@x/geocode/client';
import { Observable } from 'rxjs';

export type ITrackingConfig = {
  algolia?: IAlgoliaProviderConfig;
  logRocket?: ILogRocketProviderConfig;
  googleTagManager?: IGtmConfig;
  fireWorks?: IFireworksConfig;
};

export const TRACKING_MODULE_CONFIG = new InjectionToken<ITrackingConfig>('TRACKING_MODULE_CONFIG');

export interface ITrackingShopContext {
  user$: Observable<IShopAuthUser | null>;
  brand: string;
  flavor: string;
  defaultCountry: ICountryItem;
}

export const TRACKING_SHOP_CONTEXT = new InjectionToken<ITrackingShopContext>(
  'TRACKING_SHOP_CONTEXT',
);
