import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { logMessageByColor } from '@x/common/tracking/tracking.functions';
import {
  ITrackingShopContext,
  TRACKING_SHOP_CONTEXT,
} from '@x/common/tracking/tracking.module.config';
import { firstValueFrom } from 'rxjs';
import { GtmEvent } from '../events';

@Injectable({ providedIn: 'root' })
export class GtmService {
  private renderer: Renderer2;
  private user: Record<string, any> = {};

  constructor(
    private windowRef: WindowRef,
    private rendererFactory: RendererFactory2,
    @Inject(TRACKING_SHOP_CONTEXT) private shopContext: ITrackingShopContext,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  async sendEvent(eventData: GtmEvent) {
    const dataLayer = this.windowRef.get('dataLayer');

    if (!dataLayer?.push) {
      console.error(`No datalayer for event ${eventData.event}`);
      return;
    }

    const updateUser = await firstValueFrom(this.shopContext.user$);
    if (updateUser) {
      this.user = { userId: updateUser.id, userEmail: updateUser.email };
    }

    dataLayer.push({ ...eventData.toEvent(), ...this.user });
    console.debug({ ...eventData.toEvent(), ...this.user });
  }

  async injectScripts(containerId: string): Promise<boolean> {
    try {
      const headerScript: HTMLScriptElement = this.windowRef.document.createElement('script');
      headerScript.type = 'text/javascript';
      headerScript.defer = true;
      headerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        originalLocation: document.location.protocol + '//' +
                          document.location.hostname +
                          document.location.pathname +
                          document.location.search
      });
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${containerId}');
    `;

      const bodyScript: HTMLElement = this.windowRef.document.createElement('noscript');
      bodyScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      this.renderer.appendChild(this.windowRef.document.head, headerScript);
      this.renderer.appendChild(this.windowRef.document.body, bodyScript);

      logMessageByColor('Google Tag Manager: Initialized successfully!');

      return true;
    } catch (error) {
      console.error('Error initialising Google Tag Manager', error);

      return false;
    }
  }
}
