import { CommonModule, DecimalPipe } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TrackingAmountPipe } from '@x/common/tracking/pipes/tracking-amount.pipe';
import { initializeTracking } from '@x/common/tracking/tracking.functions';
import { ITrackingConfig, TRACKING_MODULE_CONFIG } from '@x/common/tracking/tracking.module.config';
import { TrackingService } from '@x/common/tracking/tracking.service';

@NgModule({
  declarations: [],
  providers: [DecimalPipe, TrackingAmountPipe],
  imports: [CommonModule],
})
export class TrackingModule {
  static forRoot(config?: ITrackingConfig): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      ...(config && {
        providers: [
          { provide: TRACKING_MODULE_CONFIG, useValue: config },
          TrackingService,
          {
            provide: APP_INITIALIZER,
            useFactory: initializeTracking,
            deps: [TrackingService],
            multi: true,
          },
        ],
      }),
    };
  }
}
