import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';
import {
  IShopAddress,
  IShopCheckoutOrder,
  IShopCheckoutOrderShipment,
  IShopCheckoutOrderShippingSlot,
} from '@x/ecommerce/shop-client';

type TStepId = 'shopping-bag' | 'delivery' | 'payment';

type TCheckoutEventArgs<Data> = {
  stepId: TStepId;
  data: Data;
};

export abstract class CheckoutEvent<Data extends object> extends TrackingEvent<
  TCheckoutEventArgs<Data>
> {
  [TrackingEventName] = 'checkout';

  constructor(stepId: TStepId, data: Data) {
    super({ stepId, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout', obj);
  }
}

interface ICheckoutStepChangeData {
  step: number;
  order: IShopCheckoutOrder;
}
export class TECheckoutStepChange extends CheckoutEvent<ICheckoutStepChangeData> {
  [TrackingEventName] = 'checkout:step-change';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:step-change', obj);
  }
}

interface ICheckoutPaymentData {
  paymentMethod: 'Wallet' | string;
}
export class TECheckoutPayment extends CheckoutEvent<ICheckoutPaymentData> {
  [TrackingEventName] = 'checkout:payment';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:payment', obj);
  }
}

interface TECheckoutDeliveryOwnDeliveryData {
  address: IShopAddress;
}
export class TECheckoutDeliveryOwnDelivery extends CheckoutEvent<TECheckoutDeliveryOwnDeliveryData> {
  [TrackingEventName] = 'checkout:delivery-own-delivery';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:delivery-own-delivery', obj);
  }
}

interface TECheckoutDeliveryCollectionData {
  shipment: IShopCheckoutOrderShipment;
}
export class TECheckoutDeliveryCollection extends CheckoutEvent<TECheckoutDeliveryCollectionData> {
  [TrackingEventName] = 'checkout:delivery-collection';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:delivery-collection', obj);
  }
}

interface TECheckoutDeliverySlotData {
  slot: IShopCheckoutOrderShippingSlot;
}
export class TECheckoutDeliverySlot extends CheckoutEvent<TECheckoutDeliverySlotData> {
  [TrackingEventName] = 'checkout:delivery-slot';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:delivery-slot', obj);
  }
}

type ICheckoutAction = 'Gift';
interface ICheckoutActionData {
  action: ICheckoutAction;
  step: number;
}
export class TECheckoutAction extends CheckoutEvent<ICheckoutActionData> {
  [TrackingEventName] = 'checkout:action';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('checkout:action', obj);
  }
}
