import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { IGtmEvent, IGtmPageView } from '../models';
import { GtmEvent } from './gtm-event.event';

const PARAMS_TO_OMIT = ['redirect', 'payment_intent_client_secret', 'payment_intent'];

export class GtmPageViewEvent extends GtmEvent implements IGtmPageView {
  public pageTemplate: string;
  public channel: string;
  public language: string;
  public utmParams: Params | undefined;

  constructor(eventData: IGtmPageView, eventName: IGtmEvent = 'PageView') {
    super(eventName);
    this.pageTemplate = eventData.pageTemplate;
    this.channel = eventData.channel;
    this.language = eventData.language;
    this.utmParams = eventData.utmParams;
  }

  public toEvent() {
    const baseData = super.toEvent();

    const params = this.removeSensitiveQueryParams(location.search);

    return {
      ...baseData,
      pagePath: `${this.parsePath(location.pathname)}${params ? `?${params}` : ''}`,
      pageTitle: document.title,
      pageTemplate: this.pageTemplate,
      channel: this.channel,
      language: this.language,
      utmParams: this.utmParams,
    };
  }

  private removeSensitiveQueryParams(queryString: string) {
    return PARAMS_TO_OMIT.reduce(
      (httpParams, key) => {
        return httpParams.has(key) ? httpParams.delete(key) : httpParams;
      },
      new HttpParams({ fromString: queryString }),
    );
  }

  private parsePath(pathName: string): string {
    const pathParts = pathName.split('/');

    if (pathName.includes('checkout')) {
      pathParts.splice(2, 1);
    }

    return pathParts.join('/');
  }
}

export class GtmPageLeaveEvent extends GtmPageViewEvent {
  constructor(eventData: IGtmPageView) {
    super(eventData, 'PageLeave');
  }
}
