import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName, TShopRouteOrigin } from '@x/common/tracking/tracking.types';
import { TShopOrderItemContext } from '@x/ecommerce/shop-client';

type TWishlistEventArgs<Data> = {
  routeOrigin: TShopRouteOrigin;
  data: Data;
};
abstract class WishlistEvent<Data> extends TrackingEvent<TWishlistEventArgs<Data>> {
  [TrackingEventName] = 'wishlist';

  constructor(routeOrigin: TShopRouteOrigin, data: Data) {
    super({ data, routeOrigin });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('wishlist', obj);
  }
}

type TWishlistAddItemData = {
  productId: number;
  context?: TShopOrderItemContext;
};
export class TEWishlistAddItem extends WishlistEvent<TWishlistAddItemData> {
  [TrackingEventName] = 'wishlist:add-item';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('wishlist:add-item', obj);
  }
}
