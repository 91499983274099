import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';
import { IShopProductDetail, IShopProductVariant } from '@x/ecommerce/shop-client';

type TViewEventArgs<Data> = {
  data: Data;
};

export abstract class ViewEvent<Data extends object> extends TrackingEvent<TViewEventArgs<Data>> {
  [TrackingEventName] = 'view';

  constructor(data: Data) {
    super({ data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('view', obj);
  }
}

interface IViewProductDetailData {
  product: IShopProductDetail;
  variant?: IShopProductVariant;
}
export class TEViewProductDetail extends ViewEvent<IViewProductDetailData> {
  [TrackingEventName] = 'view:product-detail';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('view:product-detail', obj);
  }
}
