import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '@x/common/browser';
import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { AlgoliaProvider } from '@x/common/tracking/providers/algolia/algolia.provider';
import { AlgoliaService } from '@x/common/tracking/providers/algolia/algolia.service';
import { FireworksProvider } from '@x/common/tracking/providers/fireworks/fireworks.provider';
import { FireworksService } from '@x/common/tracking/providers/fireworks/fireworks.service';
import { GtmProvider } from '@x/common/tracking/providers/google-tag-manager/gtm.provider';
import { GtmImpressionsService } from '@x/common/tracking/providers/google-tag-manager/services/gtm-impressions.service';
import { GtmService } from '@x/common/tracking/providers/google-tag-manager/services/gtm.service';
import { LogRocketProvider } from '@x/common/tracking/providers/log-rocket/log-rocket.provider';
import { logMessageByColor } from '@x/common/tracking/tracking.functions';
import {
  ITrackingConfig,
  ITrackingShopContext,
  TRACKING_MODULE_CONFIG,
  TRACKING_SHOP_CONTEXT,
} from '@x/common/tracking/tracking.module.config';
import { TShopRouteOrigin } from '@x/common/tracking/tracking.types';
import { TrackingProvider } from '@x/common/tracking/trackingProvider';

@Injectable()
export class TrackingService {
  private providers: TrackingProvider[] = [];

  constructor(
    @Inject(TRACKING_MODULE_CONFIG) private config: ITrackingConfig,
    @Inject(TRACKING_SHOP_CONTEXT) private shopContext: ITrackingShopContext,
    private algoliaService: AlgoliaService,
    private gtmService: GtmService,
    private gtmImpressionsService: GtmImpressionsService,
    private fireWorksService: FireworksService,
    private router: Router,
    private wr: WindowRef,
  ) {
    if (!this.wr.isBrowser) return;

    const { logRocket, algolia, googleTagManager, fireWorks } = this.config;

    if (algolia) {
      this.providers.push(new AlgoliaProvider(algolia, this.algoliaService));
    }

    if (logRocket) {
      this.providers.push(new LogRocketProvider(logRocket));
    }

    if (googleTagManager) {
      this.providers.push(
        new GtmProvider(
          googleTagManager,
          this.gtmService,
          this.gtmImpressionsService,
          this.shopContext,
        ),
      );
    }

    if (fireWorks) {
      this.providers.push(new FireworksProvider(fireWorks, this.fireWorksService));
    }
  }

  initialize(): Promise<boolean[]> {
    const promises = this.providers?.map((provider) => provider.init());
    return Promise.all(promises);
  }

  sendEvent<T extends object>(event: TrackingEvent<T>): void {
    logMessageByColor('Tracking event triggered', '#00FFFF');
    console.debug(event);

    this.providers.forEach((provider) => {
      provider.sendEvent(event);
    });
  }

  get routeOrigin(): TShopRouteOrigin {
    // Remove query parameters and split the URL to analyze its parts
    const parts = this.router.url.split('?')[0].split('/');

    // Check if there are no significant route parts beyond the channel/base path
    if (parts.length === 2 && parts[1] !== '') {
      return 'Homepage'; // Return 'Homepage' when there's only a base path
    }

    // Check for specific pages in the second segment after the base path
    const channelPages = parts[2] || ''; // Fallback to empty string if undefined
    switch (channelPages) {
      case 'ps':
        return 'Search Page';
      case 'pl':
        return 'Product Listing';
      case 't':
        return 'Taxon Listing';
      case 'p':
        return 'Product Detail';
      case 'l':
        return 'Landing Page';
      case 'my-account':
        return 'My Account';
      default:
        // If no matching route part, check the first segment after base path
        const other = parts[1];
        switch (other) {
          case 'checkout':
            return 'Checkout';
          case 'login':
            return 'Login';
          case 'register':
            return 'Register';
          default:
            return 'Unknown'; // Default to 'Unknown' if no known route is matched
        }
    }
  }
}
