import {
  TEAuthLogin,
  TEDialogClose,
  TEDialogOpen,
  TEFormAddressSearch,
  TEFormAddressSearchSelectResult,
  TEFormFieldUpdate,
  TEFormMapMovePin,
  TEFormStepChange,
  TrackingEvent,
} from '@x/common/tracking/event-models';
import {
  ILogRocketProviderConfig,
  LREventType,
} from '@x/common/tracking/providers/log-rocket/log-rocket.config';
import { logMessageByColor } from '@x/common/tracking/tracking.functions';
import { TrackingProvider } from '@x/common/tracking/trackingProvider';
import LogRocket from 'logrocket';

export class LogRocketProvider extends TrackingProvider {
  constructor(private config: ILogRocketProviderConfig) {
    super();
  }

  async init(): Promise<boolean> {
    const { appId } = this.config;
    try {
      LogRocket.init(appId);
      logMessageByColor('LogRocket: Initialized successfully!');
    } catch (e) {
      console.error('Error initialising Logrocket', e);
    }

    return true;
  }

  sendEvent<T extends object>(event: TrackingEvent<T>): void {
    try {
      if (event instanceof TEDialogOpen) {
        const { shopOrigin, data } = event.context;
        this.sendLREvent('DialogOpen', { origin: shopOrigin, ...data });
        return;
      }

      if (event instanceof TEDialogClose) {
        const { shopOrigin, data } = event.context;
        this.sendLREvent('DialogClose', { origin: shopOrigin, ...data });
        return;
      }

      if (event instanceof TEFormStepChange) {
        const { formOrigin, data } = event.context;
        this.sendLREvent('FormChangeStep', { formOrigin: formOrigin, ...data });
        return;
      }

      if (event instanceof TEFormAddressSearch) {
        const { formOrigin, data } = event.context;
        this.sendLREvent('FormAddressSearch', { formOrigin: formOrigin, ...data });
        return;
      }

      if (event instanceof TEFormAddressSearchSelectResult) {
        const { formOrigin, data } = event.context;
        this.sendLREvent('FormAddressSearchSelectResult', { formOrigin: formOrigin, ...data });
        return;
      }

      if (event instanceof TEFormMapMovePin) {
        const { formOrigin, data } = event.context;
        this.sendLREvent('FormMapMovePin', { formOrigin: formOrigin, ...data });
        return;
      }

      if (event instanceof TEFormFieldUpdate) {
        const { formOrigin, data } = event.context;
        this.sendLREvent('FormFieldUpdate', { formOrigin: formOrigin, ...data });
        return;
      }

      if (event instanceof TEAuthLogin) {
        const { firstName, email, id } = event.context.data.user;
        const logRocketId = `${id}`;

        /*Update log rocket session*/
        LogRocket.identify(logRocketId, {
          name: firstName,
          email: email,
        });

        return;
      }
    } catch (e) {
      console.error('Error sending event to LogRocket', e);
    }
  }

  private sendLREvent(type: LREventType, payload: any) {
    LogRocket.track(type, { ...payload });
    console.debug('Event sent to LogRocket', type, payload);
  }
}
