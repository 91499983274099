import { Injectable, OnDestroy } from '@angular/core';
import { TAssociationOrigin } from '@x/common/tracking';
import { TEImpressionProduct } from '@x/common/tracking/event-models';
import {
  GtmEvent,
  GtmImpressionEvent,
} from '@x/common/tracking/providers/google-tag-manager/events';
import { GtmService } from '@x/common/tracking/providers/google-tag-manager/services/gtm.service';
import { slugify } from '@x/common/utils';
import { IShopProductItem } from '@x/ecommerce/shop-client';
import { Subject, Subscription, bufferTime } from 'rxjs';
import { filter } from 'rxjs/operators';

type TProductGroup = {
  products: IShopProductItem[];
  item_list_id: number | string;
  item_list_name?: string;
  associationId?: number;
  associationOrigin?: TAssociationOrigin;
};
type TProductGroupBy = Record<string, TProductGroup>;

@Injectable({ providedIn: 'root' })
export class GtmImpressionsService implements OnDestroy {
  private subscription = new Subscription();
  private impression = new Subject<TEImpressionProduct>();

  constructor(private gtmService: GtmService) {
    this.subscription.add(
      this.impression
        .pipe(
          bufferTime(2000),
          filter((impressions) => impressions.length > 0),
        )
        .subscribe({
          next: (events) => {
            for (const value of Object.values(this.groupByListId(events))) {
              this.sendEvent(value);
            }
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  accumulateEvents(event: TEImpressionProduct) {
    this.impression.next(event);
  }

  private sendEvent(event: TProductGroup) {
    const { products, item_list_name, item_list_id, associationOrigin, associationId } = event;

    const gtmEvent: GtmImpressionEvent = new GtmImpressionEvent();
    gtmEvent.buildEvent(products, item_list_id, item_list_name);
    this.gtmService.sendEvent(gtmEvent);

    if (associationOrigin && associationId) {
      const gtmEvent = new GtmEvent('AssociationsViewed');
      const gtmImpression = new GtmImpressionEvent();
      gtmImpression.buildEvent(products, associationId, item_list_name);

      gtmEvent.genericEvent({
        associationProductCount: products.length,
        associationImpressions: gtmImpression.ecommerce.impressions,
        associationId: associationId,
        associationTitle: item_list_name,
        associationOrigin: associationOrigin,
      });

      this.gtmService.sendEvent(gtmEvent);
    }
  }

  private groupByListId(data: TEImpressionProduct[]): TProductGroupBy {
    return data.reduce((acc, curr) => {
      const {
        routeOrigin,
        data: { taxonId, associationId, listName, product, associationOrigin },
      } = curr.context;

      const _id = taxonId
        ? `/taxon_id/${taxonId}`
        : associationId
          ? `/association_id/${associationId}`
          : '';

      const _listName = listName ? `/${slugify(listName)}` : '';

      const listId = `${slugify(routeOrigin)}${_id}${_listName}`;

      if (!acc[listId]) {
        acc[listId] = {
          products: [],
          item_list_id: listId,
          item_list_name: listName,
          associationId,
          associationOrigin,
        };
      }
      acc[listId].products.push(product);
      return acc;
    }, {} as TProductGroupBy);
  }
}
