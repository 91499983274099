import { TAdPlacement } from '@x/common/ads/ad.config';
import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName, TShopRouteOrigin } from '@x/common/tracking/tracking.types';
import { IProductItem } from '@x/ecommerce-shop/app/core/product/types/product-item';

type TClickEventArgs<Data> = {
  routeOrigin: TShopRouteOrigin;
  data: Data;
};

export abstract class ClickEvent<Data extends object> extends TrackingEvent<TClickEventArgs<Data>> {
  [TrackingEventName] = 'click';

  constructor(routeOrigin: TShopRouteOrigin, data: Data) {
    super({ routeOrigin, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('click', obj);
  }
}

type TClickProductItemData = {
  productItem: IProductItem;
};

export class TEClickProductItem extends ClickEvent<TClickProductItemData> {
  [TrackingEventName] = 'click.product-item';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('click.product-item', obj);
  }
}

type TClickShopAdData = {
  shopAdId: number;
  placement: TAdPlacement;
  taxonId?: number;
};

export class TEClickShopAd extends ClickEvent<TClickShopAdData> {
  [TrackingEventName] = 'click.shop-ad';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('click.shop-ad', obj);
  }
}
