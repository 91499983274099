import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  TEAuthLogin,
  TEAuthLogout,
  TEAuthRegister,
} from '@x/common/tracking/event-models/auth.events';
import { TrackingService } from '@x/common/tracking/tracking.service';
import { CheckoutRefreshOrder } from '@x/ecommerce-shop/app/checkout/state/checkout-order.actions';
import { ShopStorage } from '@x/ecommerce-shop/app/core/shop-storage/shop-storage.service';
import {
  IShopAuthUser,
  IShopUserAddress,
  IShopUserPaymentMethod,
  ShopSubscriberService,
  ShopUserService,
} from '@x/ecommerce/shop-client';
import { firstValueFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  AuthCreateUserAddress,
  AuthDeleteUserAddress,
  AuthGetUserAddresses,
  AuthLogin,
  AuthLogout,
  AuthRefreshUserAddresses,
  AuthRefreshUserPaymentMethods,
  AuthRegister,
  AuthSetUser,
  AuthUpdateArchiveUserPaymentMethod,
  AuthUpdatePassword,
  AuthUpdateUser,
  AuthUpdateUserAddress,
  AuthUpdateUserDeclaration,
  AuthUser,
} from './auth.actions';

export interface AuthStateModel {
  loggedInUser: IShopAuthUser | null;
  userAddresses: IShopUserAddress[];
}

const defaults: AuthStateModel = {
  loggedInUser: null,
  userAddresses: [],
};

@State<AuthStateModel>({
  name: 'authState',
  defaults,
})
@Injectable()
export class AuthState implements NgxsOnInit {
  constructor(
    private shopUserService: ShopUserService,
    private shopSubscriberService: ShopSubscriberService,
    private shopStorage: ShopStorage,
    private analyticsService: TrackingService,
  ) {}

  @Selector()
  static loggedInUser(state: AuthStateModel): IShopAuthUser | null {
    return state.loggedInUser;
  }

  @Selector()
  static isLoggedIn(state: AuthStateModel): boolean {
    return state.loggedInUser !== null;
  }

  @Selector()
  static isLoggedOut(state: AuthStateModel): boolean {
    return state.loggedInUser === null;
  }

  @Selector()
  static userAddresses(state: AuthStateModel): IShopUserAddress[] {
    return state.userAddresses;
  }

  @Selector()
  static userActivePaymentMethods(state: AuthStateModel): IShopUserPaymentMethod[] | undefined {
    return state.loggedInUser?.paymentMethods.filter((m) => {
      return !m.isExpired && m.state === 'ACTIVE';
    });
  }

  @Action(AuthRegister)
  register({ dispatch, getState }: StateContext<AuthStateModel>, { input }: AuthRegister) {
    return this.shopUserService.registerUser(input).pipe(
      tap((token) => (this.shopStorage.userToken = token)),
      switchMap(() => dispatch(new AuthUser())),
      tap(() => {
        const { loggedInUser } = getState();
        if (!loggedInUser) return;

        const { id, email, firstName } = loggedInUser;

        this.analyticsService.sendEvent(new TEAuthRegister({ user: { id, email, firstName } }));
      }),
    );
  }

  @Action(AuthLogin)
  login({ dispatch }: StateContext<AuthStateModel>, { input }: AuthLogin) {
    return this.shopUserService.loginUser(input).pipe(
      tap((token) => (this.shopStorage.userToken = token)),
      switchMap(() => dispatch(new AuthUser())),
    );
  }

  @Action(AuthUser)
  authenticate(state: StateContext<AuthStateModel>) {
    return this.shopUserService
      .authUser()
      .pipe(switchMap((user) => state.dispatch(new AuthSetUser(user))));
  }

  @Action(AuthSetUser)
  setUser({ dispatch, patchState }: StateContext<AuthStateModel>, { user }: AuthSetUser) {
    patchState({ loggedInUser: { ...user } });

    // analytics event
    this.analyticsService.sendEvent(
      new TEAuthLogin({
        user: {
          email: user.email,
          id: user.id,
          firstName: user.firstName,
        },
      }),
    );

    return dispatch(new AuthGetUserAddresses());
  }

  @Action(AuthLogout)
  logout({ setState }: StateContext<AuthStateModel>) {
    this.shopStorage.userToken = null;
    this.shopStorage.recentSearches = null;

    this.analyticsService.sendEvent(new TEAuthLogout());

    setState({ ...defaults });
  }

  @Action(AuthCreateUserAddress)
  createUserAddress(state: StateContext<AuthStateModel>, { input }: AuthCreateUserAddress) {
    const userId = this.getUserIdFromStorage(state.dispatch);
    if (!userId) return;

    return this.shopUserService
      .createUserAddress({ input: { ...input, userId } })
      .pipe(switchMap(() => state.dispatch(new AuthRefreshUserAddresses())));
  }

  @Action(AuthUpdateUserAddress)
  updateUserAddress(state: StateContext<AuthStateModel>, { input }: AuthUpdateUserAddress) {
    const userId = this.getUserIdFromStorage(state.dispatch);
    if (!userId) return;

    return this.shopUserService
      .updateUserAddress({ input: { ...input, userId } })
      .pipe(switchMap(() => state.dispatch(new AuthRefreshUserAddresses())));
  }

  @Action(AuthDeleteUserAddress)
  deleteUserAddress(state: StateContext<AuthStateModel>, { input }: AuthDeleteUserAddress) {
    const userId = this.getUserIdFromStorage(state.dispatch);
    if (!userId) return;

    return this.shopUserService
      .deleteUserAddress({ input: { ...input, userId } })
      .pipe(switchMap(() => state.dispatch(new AuthRefreshUserAddresses())));
  }

  @Action(AuthGetUserAddresses)
  getUserAddresses({ dispatch, getState }: StateContext<AuthStateModel>) {
    if (getState().userAddresses.length > 0) return;

    return dispatch(new AuthRefreshUserAddresses());
  }

  @Action(AuthRefreshUserAddresses)
  refreshUserAddresses(state: StateContext<AuthStateModel>) {
    return this.shopUserService
      .fetchAddresses()
      .pipe(tap((userAddresses) => state.patchState({ userAddresses })));
  }

  @Action(AuthUpdateUser)
  updateUser(state: StateContext<AuthStateModel>, { input }: AuthUpdateUser) {
    const userId = this.getUserIdFromStorage(state.dispatch);
    if (!userId) return;

    return this.shopUserService
      .updateUser({ input: { ...input, userId } })
      .pipe(switchMap((user) => state.dispatch(new AuthSetUser(user))));
  }

  @Action(AuthUpdatePassword)
  updatePassword(state: StateContext<AuthStateModel>, { password }: AuthUpdatePassword) {
    const userId = this.getUserIdFromStorage(state.dispatch);
    if (!userId) return;

    return this.shopUserService.updateUserPassword({ password, userId });
  }

  @Action(AuthUpdateArchiveUserPaymentMethod)
  archiveUserPaymentMethod(
    state: StateContext<AuthStateModel>,
    { input }: AuthUpdateArchiveUserPaymentMethod,
  ) {
    const { userPaymentMethodId } = input;

    return this.shopUserService
      .archiveUserPaymentMethodGQL({ input: { userPaymentMethodId } })
      .pipe(switchMap(() => state.dispatch(new AuthRefreshUserPaymentMethods())));
  }

  @Action(AuthUpdateUserDeclaration)
  updateUserDeclaration(state: StateContext<AuthStateModel>, { args }: AuthUpdateUserDeclaration) {
    return this.shopUserService
      .updateUserDeclaration({ input: { ...args } })
      .pipe(switchMap(() => state.dispatch(new CheckoutRefreshOrder())));
  }

  @Action(AuthRefreshUserPaymentMethods)
  refreshUserPaymentMethods({ getState, patchState }: StateContext<AuthStateModel>) {
    const loggedInUser = getState().loggedInUser;

    if (!loggedInUser) return;

    return this.shopUserService.refreshPaymentMethods().pipe(
      tap((methods) => {
        patchState({
          loggedInUser: {
            ...loggedInUser,
            paymentMethods: methods,
          },
        });
      }),
    );
  }

  async ngxsOnInit({ dispatch }: StateContext<AuthStateModel>): Promise<void> {
    try {
      if (!this.shopStorage.userToken?.token) return;

      this.shopStorage.userToken = await firstValueFrom(this.shopUserService.refreshUserToken());

      // auth user
      dispatch(new AuthUser());
    } catch (err) {
      console.info('%cCould not refresh auth token on Token init', 'color: DodgerBlue');

      // logout
      dispatch(new AuthLogout());
    }
  }

  private getUserIdFromStorage(dispatch: Function): number | undefined {
    const userId = this.shopStorage.userToken?.userId;
    if (!userId) {
      dispatch(new AuthLogout());
      return;
    }
    return userId;
  }
}
