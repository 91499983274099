import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';

type TAuthActions = 'login' | 'logout' | 'register';

type TAuthEventArgs<Data = {}> = {
  action: TAuthActions;
  data: Data;
};

export abstract class AuthEvent<Data extends object = {}> extends TrackingEvent<
  TAuthEventArgs<Data>
> {
  [TrackingEventName] = 'auth';

  protected constructor(action: TAuthActions, data: Data = {} as Data) {
    super({ action, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('auth', obj);
  }
}

type TAuthUserData = {
  user: {
    id: number;
    email: string;
    firstName: string;
  };
};
export class TEAuthLogin extends AuthEvent<TAuthUserData> {
  [TrackingEventName] = 'auth:login';

  constructor(data: TAuthUserData) {
    super('login', data);
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('auth:login', obj);
  }
}

export class TEAuthLogout extends AuthEvent {
  [TrackingEventName] = 'auth:logout';

  constructor() {
    super('logout');
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('auth:logout', obj);
  }
}

export class TEAuthRegister extends AuthEvent<TAuthUserData> {
  [TrackingEventName] = 'auth:register';

  constructor(data: TAuthUserData) {
    super('register', data);
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('auth:register', obj);
  }
}
