import { IShopCheckoutOrder, IShopCurrency } from '@x/ecommerce/shop-client';
import { IGtmEvent, IGtmProduct } from '../models';
import { GtmEvent } from './gtm-event.event';

export enum PurchaseAffiliation {
  SHOP = 'shop',
  SUBSCRIBE = 'subscribe',
}

export interface IGtmPurchaseEvent {
  purchase: {
    actionField?: {
      id: number;
      affiliation: PurchaseAffiliation;
      revenue?: number;
      tax: number;
      shipping: number;
      coupon: number;
    };
    currencyCode?: string;
    products: IGtmProduct[];
  };
}

export class GtmPurchaseEvent extends GtmEvent {
  public ecommerce: IGtmPurchaseEvent;

  constructor(name: IGtmEvent) {
    super(name);
    this.ecommerce = { purchase: { actionField: undefined, products: [] } };
  }

  public fromProduct(
    payment: { id: number; amount: number; currency: string },
    order: IShopCheckoutOrder,
  ): void {
    this.ecommerce.purchase.actionField = {
      id: payment.id,
      affiliation: PurchaseAffiliation.SHOP,
      revenue: payment.amount / 100,
      tax: order.taxTotal / 100,
      shipping: 0,
      coupon: order.adjustmentsTotal / 100,
    };
    this.ecommerce.purchase.currencyCode = payment.currency;
    this.ecommerce.purchase.products = order.items.map((product) => this.convertToProduct(product));
  }

  public fromSubscription(subscription: { id: number; price?: IShopCurrency | null }): void {
    const summary = {
      id: subscription.id,
      affiliation: PurchaseAffiliation.SUBSCRIBE,
      ...(subscription.price?.amount && { revenue: subscription.price.amount / 100 }),
      tax: 0,
      shipping: 0,
      coupon: 0,
    };
    this.ecommerce.purchase.currencyCode = subscription.price?.currency;
    this.ecommerce.purchase.actionField = summary;
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
