import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName } from '@x/common/tracking/tracking.types';

type TABTestEventArgs<Data> = {
  data: Data;
};

export abstract class ABTestEvent<Data extends object> extends TrackingEvent<
  TABTestEventArgs<Data>
> {
  [TrackingEventName] = 'ab-test';

  constructor(data: Data) {
    super({ data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('ab-test', obj);
  }
}

type TExperimentViewedData = {
  experimentId: string;
  variationId: string;
  deviceId?: string;
};
export class TEABTestExperimentViewed extends ABTestEvent<TExperimentViewedData> {
  [TrackingEventName] = 'ab-test:experiment-viewed';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('ab-test:experiment-viewed', obj);
  }
}
