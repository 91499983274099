var version = "2.17.3";
function extractAdditionalParams(e) {
  return e.reduce(function (e, t) {
    var n = e.events,
      e = e.additionalParams;
    return "index" in t ? {
      additionalParams: e,
      events: n.concat([t])
    } : {
      events: n,
      additionalParams: t
    };
  }, {
    events: [],
    additionalParams: void 0
  });
}
var supportsCookies = function () {
    try {
      return Boolean(navigator.cookieEnabled);
    } catch (e) {
      return !1;
    }
  },
  supportsSendBeacon = function () {
    try {
      return Boolean(navigator.sendBeacon);
    } catch (e) {
      return !1;
    }
  },
  supportsXMLHttpRequest = function () {
    try {
      return Boolean(XMLHttpRequest);
    } catch (e) {
      return !1;
    }
  },
  supportsNativeFetch = function () {
    try {
      return void 0 !== fetch;
    } catch (e) {
      return !1;
    }
  },
  LocalStorage = function () {};
function ensureLocalStorage() {
  try {
    var e = "__test_localStorage__";
    return globalThis.localStorage.setItem(e, e), globalThis.localStorage.removeItem(e), globalThis.localStorage;
  } catch (e) {}
}
LocalStorage.get = function (e) {
  var t = null == (t = this.store) ? void 0 : t.getItem(e);
  if (!t) return null;
  try {
    return JSON.parse(t);
  } catch (e) {
    return null;
  }
}, LocalStorage.set = function (t, e) {
  var n;
  try {
    null != (n = this.store) && n.setItem(t, JSON.stringify(e));
  } catch (e) {
    console.error("Unable to set " + t + " in localStorage, storage may be full.");
  }
}, LocalStorage.remove = function (e) {
  var t;
  null != (t = this.store) && t.removeItem(e);
}, LocalStorage.store = ensureLocalStorage();
var STORE = "AlgoliaObjectQueryCache",
  LIMIT = 5e3,
  FREE = 1e3;
function getCache() {
  var e;
  return null != (e = LocalStorage.get(STORE)) ? e : {};
}
function setCache(e) {
  LocalStorage.set(STORE, limited(e));
}
function limited(e) {
  return Object.keys(e).length > LIMIT ? purgeOldest(e) : e;
}
function purgeOldest(e) {
  e = Object.entries(e).sort(function (e, t) {
    e = e[1][1];
    return t[1][1] - e;
  });
  return e.slice(0, e.length - FREE - 1).reduce(function (e, t) {
    var n = t[0],
      t = t[1];
    return Object.assign(Object.assign({}, e), ((e = {})[n] = t, e));
  }, {});
}
function makeKey(e, t) {
  return e + "_" + t;
}
function storeQueryForObject(e, t, n) {
  var i = getCache();
  i[makeKey(e, t)] = [n, Date.now()], setCache(i);
}
function getQueryForObject(e, t) {
  return getCache()[makeKey(e, t)];
}
function removeQueryForObjects(t, e) {
  var n = getCache();
  e.forEach(function (e) {
    delete n[makeKey(t, e)];
  }), setCache(n);
}
var isUndefined = function (e) {
    return void 0 === e;
  },
  isNumber = function (e) {
    return "number" == typeof e;
  },
  isFunction = function (e) {
    return "function" == typeof e;
  },
  isPromise = function (e) {
    return "function" == typeof (null == e ? void 0 : e.then);
  };
function getFunctionalInterface(i) {
  return function (e) {
    for (var t = [], n = arguments.length - 1; 0 < n--;) t[n] = arguments[n + 1];
    if (e && isFunction(i[e])) return i[e].apply(i, t);
    console.warn("The method `" + e + "` doesn't exist.");
  };
}
var DEFAULT_ALGOLIA_AGENTS = ["insights-js (" + version + ")", "insights-js-browser-esm (" + version + ")"];
function addAlgoliaAgent(e) {
  -1 === this._ua.indexOf(e) && this._ua.push(e);
}
function getVersion(e) {
  return isFunction(e) && e(this.version), this.version;
}
function __rest(e, t) {
  var n = {};
  for (r in e) Object.prototype.hasOwnProperty.call(e, r) && t.indexOf(r) < 0 && (n[r] = e[r]);
  if (null != e && "function" == typeof Object.getOwnPropertySymbols) for (var i = 0, r = Object.getOwnPropertySymbols(e); i < r.length; i++) t.indexOf(r[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[i]) && (n[r[i]] = e[r[i]]);
  return n;
}
function addQueryId(e) {
  return e.map(function (i) {
    var r, e;
    return !isValidEventForQueryIdLookup(i) || (r = [], e = null == (e = i.objectIDs) ? void 0 : e.map(function (e, t) {
      var n = null == (n = i.objectData) ? void 0 : n[t];
      return null != n && n.queryID ? n : ((t = (null != (t = getQueryForObject(i.index, e)) ? t : [])[0]) && r.push(e), Object.assign(Object.assign({}, n), {
        queryID: t
      }));
    }), 0 === r.length) ? i : Object.assign(Object.assign({}, i), {
      objectData: e,
      objectIDsWithInferredQueryID: r
    });
  });
}
function isValidEventForQueryIdLookup(e) {
  return !e.queryID && "conversion" === e.eventType;
}
function makeSendEvents(r) {
  return function (e, t) {
    var i = this;
    if (this._userHasOptedOut) return Promise.resolve(!1);
    if (!(!isUndefined(this._apiKey) && !isUndefined(this._appId) || (null == (n = null == t ? void 0 : t.headers) ? void 0 : n["X-Algolia-Application-Id"]) && (null == (n = null == t ? void 0 : t.headers) ? void 0 : n["X-Algolia-API-Key"]))) throw new Error("Before calling any methods on the analytics, you first need to call the 'init' function with appId and apiKey parameters or provide custom credentials in additional parameters.");
    !this._userToken && this._anonymousUserToken && this.setAnonymousUserToken(!0);
    var n = (null != t && t.inferQueryID ? addQueryId(e) : e).map(function (e) {
      var t = e.filters,
        n = __rest(e, ["filters"]),
        e = Object.assign(Object.assign({}, n), {
          userToken: null != (n = null == e ? void 0 : e.userToken) ? n : i._userToken,
          authenticatedUserToken: null != (n = null == e ? void 0 : e.authenticatedUserToken) ? n : i._authenticatedUserToken
        });
      return isUndefined(t) || (e.filters = t.map(encodeURIComponent)), e;
    });
    return 0 === n.length ? Promise.resolve(!1) : (e = sendRequest(r, this._ua, this._endpointOrigin, n, this._appId, this._apiKey, null == t ? void 0 : t.headers), isPromise(e) ? e.then(purgePurchased(n)) : e);
  };
}
function purgePurchased(t) {
  return function (e) {
    return e && t.filter(function (e) {
      var t = e.eventType,
        n = e.eventSubtype,
        e = e.objectIDs;
      return "conversion" === t && "purchase" === n && (null == e ? void 0 : e.length);
    }).forEach(function (e) {
      return removeQueryForObjects(e.index, e.objectIDs);
    }), e;
  };
}
function sendRequest(e, t, n, i, r, o, s) {
  var a = (s = void 0 === s ? {} : s)["X-Algolia-Application-Id"],
    c = s["X-Algolia-API-Key"],
    s = __rest(s, ["X-Algolia-Application-Id", "X-Algolia-API-Key"]),
    u = Object.assign({
      "X-Algolia-Application-Id": null != a ? a : r,
      "X-Algolia-API-Key": null != c ? c : o,
      "X-Algolia-Agent": encodeURIComponent(t.join("; "))
    }, s);
  return e(n + "/1/events?" + Object.keys(u).map(function (e) {
    return e + "=" + u[e];
  }).join("&"), {
    events: i
  });
}
function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (e) {
    var t = 16 * Math.random() | 0;
    return ("x" === e ? t : 3 & t | 8).toString(16);
  });
}
var COOKIE_KEY = "_ALGOLIA",
  MONTH = 2592e6,
  setCookie = function (e, t, n) {
    var i = new Date(),
      n = (i.setTime(i.getTime() + n), "expires=" + i.toUTCString());
    document.cookie = e + "=" + t + ";" + n + ";path=/";
  },
  getCookie = function (e) {
    for (var t = e + "=", n = document.cookie.split(";"), i = 0; i < n.length; i++) {
      for (var r = n[i]; " " === r.charAt(0);) r = r.substring(1);
      if (0 === r.indexOf(t)) return r.substring(t.length, r.length);
    }
    return "";
  };
function checkIfAnonymousToken(e) {
  return "number" != typeof e && 0 === e.indexOf("anonymous-");
}
function saveTokenAsCookie() {
  var e = getCookie(COOKIE_KEY);
  !this._userToken || e && "" !== e && 0 === e.indexOf("anonymous-") || setCookie(COOKIE_KEY, this._userToken, this._cookieDuration);
}
function setAnonymousUserToken(e) {
  (e = void 0 !== e && e) ? this.setUserToken("anonymous-" + createUUID()) : supportsCookies() && ((e = getCookie(COOKIE_KEY)) && "" !== e && 0 === e.indexOf("anonymous-") ? this.setUserToken(e) : (e = this.setUserToken("anonymous-" + createUUID()), setCookie(COOKIE_KEY, e, this._cookieDuration)));
}
function setUserToken(e) {
  return this._userToken = e, isFunction(this._onUserTokenChangeCallback) && this._onUserTokenChangeCallback(this._userToken), this._userToken;
}
function getUserToken(e, t) {
  return isFunction(t) && t(null, this._userToken), this._userToken;
}
function onUserTokenChange(e, t) {
  this._onUserTokenChangeCallback = e, t && t.immediate && isFunction(this._onUserTokenChangeCallback) && this._onUserTokenChangeCallback(this._userToken);
}
function setAuthenticatedUserToken(e) {
  return this._authenticatedUserToken = e, isFunction(this._onAuthenticatedUserTokenChangeCallback) && this._onAuthenticatedUserTokenChangeCallback(this._authenticatedUserToken), this._authenticatedUserToken;
}
function getAuthenticatedUserToken(e, t) {
  return isFunction(t) && t(null, this._authenticatedUserToken), this._authenticatedUserToken;
}
function onAuthenticatedUserTokenChange(e, t) {
  this._onAuthenticatedUserTokenChangeCallback = e, t && t.immediate && isFunction(this._onAuthenticatedUserTokenChangeCallback) && this._onAuthenticatedUserTokenChangeCallback(this._authenticatedUserToken);
}
function addEventType(t, e) {
  return e.map(function (e) {
    return Object.assign({
      eventType: t
    }, e);
  });
}
function addEventTypeAndSubtype(t, n, e) {
  return e.map(function (e) {
    return Object.assign({
      eventType: t,
      eventSubtype: n
    }, e);
  });
}
function clickedObjectIDsAfterSearch() {
  for (var i = this, e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    r = n.events,
    n = n.additionalParams;
  return r.forEach(function (e) {
    var t = e.index,
      n = e.queryID;
    return e.objectIDs.forEach(function (e) {
      return !i._userHasOptedOut && storeQueryForObject(t, e, n);
    });
  }), this.sendEvents(addEventType("click", r), n);
}
function clickedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("click", i), n);
}
function clickedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("click", i), n);
}
function convertedObjectIDsAfterSearch() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("conversion", i), n);
}
function addedToCartObjectIDsAfterSearch() {
  for (var o = this, e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return i.forEach(function (e) {
    var n = e.index,
      i = e.queryID,
      t = e.objectIDs,
      r = e.objectData;
    return t.forEach(function (e, t) {
      t = null != (t = null == (t = null == r ? void 0 : r[t]) ? void 0 : t.queryID) ? t : i;
      !o._userHasOptedOut && t && storeQueryForObject(n, e, t);
    });
  }), this.sendEvents(addEventTypeAndSubtype("conversion", "addToCart", i), n);
}
function purchasedObjectIDsAfterSearch() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventTypeAndSubtype("conversion", "purchase", i), n);
}
function convertedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("conversion", i), n);
}
function addedToCartObjectIDs() {
  for (var r = this, e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return i.forEach(function (e) {
    var n = e.index,
      t = e.objectIDs,
      i = e.objectData;
    return t.forEach(function (e, t) {
      t = null == (t = null == i ? void 0 : i[t]) ? void 0 : t.queryID;
      !r._userHasOptedOut && t && storeQueryForObject(n, e, t);
    });
  }), this.sendEvents(addEventTypeAndSubtype("conversion", "addToCart", i), n);
}
function purchasedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventTypeAndSubtype("conversion", "purchase", i), n);
}
function convertedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("conversion", i), n);
}
var SUPPORTED_REGIONS = ["de", "us"];
function init(e) {
  var t;
  if (!isUndefined((e = void 0 === e ? {} : e).region) && -1 === SUPPORTED_REGIONS.indexOf(e.region)) throw new Error("optional region is incorrect, please provide either one of: " + SUPPORTED_REGIONS.join(", ") + ".");
  if (!(isUndefined(e.cookieDuration) || isNumber(e.cookieDuration) && isFinite(e.cookieDuration) && Math.floor(e.cookieDuration) === e.cookieDuration)) throw new Error("optional cookieDuration is incorrect, expected an integer.");
  setOptions(this, e, {
    _userHasOptedOut: Boolean(e.userHasOptedOut),
    _region: e.region,
    _host: e.host,
    _anonymousUserToken: null == (t = e.anonymousUserToken) || t,
    _useCookie: null != (t = e.useCookie) && t,
    _cookieDuration: e.cookieDuration || 6 * MONTH
  }), this._endpointOrigin = this._host || (this._region ? "https://insights." + this._region + ".algolia.io" : "https://insights.algolia.io"), this._ua = [].concat(DEFAULT_ALGOLIA_AGENTS), e.authenticatedUserToken && this.setAuthenticatedUserToken(e.authenticatedUserToken), e.userToken ? this.setUserToken(e.userToken) : this._userToken || this._userHasOptedOut || !this._useCookie ? checkIfTokenNeedsToBeSaved(this) && this.saveTokenAsCookie() : this.setAnonymousUserToken();
}
function setOptions(e, t, n) {
  var i = t.partial,
    r = __rest(t, ["partial"]);
  i || Object.assign(e, n), Object.assign(e, Object.keys(r).reduce(function (e, t) {
    return Object.assign(Object.assign({}, e), ((e = {})["_" + t] = r[t], e));
  }, {}));
}
function checkIfTokenNeedsToBeSaved(e) {
  return void 0 !== e._userToken && checkIfAnonymousToken(e._userToken) && e._useCookie && !e._userHasOptedOut;
}
function viewedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("view", i), n);
}
function viewedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];
  var n = extractAdditionalParams(e),
    i = n.events,
    n = n.additionalParams;
  return this.sendEvents(addEventType("view", i), n);
}
var AlgoliaAnalytics = function (e) {
  e = e.requestFn;
  this._endpointOrigin = "https://insights.algolia.io", this._anonymousUserToken = !0, this._userHasOptedOut = !1, this._useCookie = !1, this._cookieDuration = 6 * MONTH, this._ua = [], this.version = version, this.sendEvents = makeSendEvents(e).bind(this), this.init = init.bind(this), this.addAlgoliaAgent = addAlgoliaAgent.bind(this), this.saveTokenAsCookie = saveTokenAsCookie.bind(this), this.setUserToken = setUserToken.bind(this), this.setAnonymousUserToken = setAnonymousUserToken.bind(this), this.getUserToken = getUserToken.bind(this), this.onUserTokenChange = onUserTokenChange.bind(this), this.setAuthenticatedUserToken = setAuthenticatedUserToken.bind(this), this.getAuthenticatedUserToken = getAuthenticatedUserToken.bind(this), this.onAuthenticatedUserTokenChange = onAuthenticatedUserTokenChange.bind(this), this.clickedObjectIDsAfterSearch = clickedObjectIDsAfterSearch.bind(this), this.clickedObjectIDs = clickedObjectIDs.bind(this), this.clickedFilters = clickedFilters.bind(this), this.convertedObjectIDsAfterSearch = convertedObjectIDsAfterSearch.bind(this), this.purchasedObjectIDsAfterSearch = purchasedObjectIDsAfterSearch.bind(this), this.addedToCartObjectIDsAfterSearch = addedToCartObjectIDsAfterSearch.bind(this), this.convertedObjectIDs = convertedObjectIDs.bind(this), this.addedToCartObjectIDs = addedToCartObjectIDs.bind(this), this.purchasedObjectIDs = purchasedObjectIDs.bind(this), this.convertedFilters = convertedFilters.bind(this), this.viewedObjectIDs = viewedObjectIDs.bind(this), this.viewedFilters = viewedFilters.bind(this), this.getVersion = getVersion.bind(this);
};
function createInsightsClient(e) {
  var t,
    e = getFunctionalInterface(new AlgoliaAnalytics({
      requestFn: e
    }));
  if ("object" == typeof window && !window.AlgoliaAnalyticsObject) {
    for (; t = createUUID(), void 0 !== window[t];);
    window.AlgoliaAnalyticsObject = t, window[window.AlgoliaAnalyticsObject] = e;
  }
  return e.version = version, e;
}
function processQueue(e) {
  var n,
    t = e.AlgoliaAnalyticsObject;
  t && (n = getFunctionalInterface(this), (e = e[t]).queue = e.queue || [], (t = e.queue).forEach(function (e) {
    var e = [].slice.call(e),
      t = e[0],
      e = e.slice(1);
    n.apply(void 0, [t].concat(e));
  }), t.push = function (e) {
    var e = [].slice.call(e),
      t = e[0],
      e = e.slice(1);
    n.apply(void 0, [t].concat(e));
  });
}
var requestWithXMLHttpRequest = function (r, o) {
    return new Promise(function (e, t) {
      var n = JSON.stringify(o),
        i = new XMLHttpRequest();
      i.addEventListener("readystatechange", function () {
        4 === i.readyState && 200 === i.status ? e(!0) : 4 === i.readyState && e(!1);
      }), i.addEventListener("error", function () {
        return t();
      }), i.addEventListener("timeout", function () {
        return e(!1);
      }), i.open("POST", r), i.setRequestHeader("Content-Type", "application/json"), i.send(n);
    });
  },
  requestWithSendBeacon = function (e, t) {
    var n = JSON.stringify(t),
      n = navigator.sendBeacon(e, n);
    return Promise.resolve(!!n || requestWithXMLHttpRequest(e, t));
  },
  requestWithNativeFetch = function (e, i) {
    return new Promise(function (t, n) {
      fetch(e, {
        method: "POST",
        body: JSON.stringify(i),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(function (e) {
        t(200 === e.status);
      }).catch(function (e) {
        n(e);
      });
    });
  };
function getRequesterForBrowser() {
  if (supportsSendBeacon()) return requestWithSendBeacon;
  if (supportsXMLHttpRequest()) return requestWithXMLHttpRequest;
  if (supportsNativeFetch()) return requestWithNativeFetch;
  throw new Error("Could not find a supported HTTP request client in this environment.");
}
var entryBrowser = createInsightsClient(getRequesterForBrowser());
export { AlgoliaAnalytics, LocalStorage, createInsightsClient, entryBrowser as default, getFunctionalInterface, getRequesterForBrowser, processQueue };