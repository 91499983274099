import { IShopOrderItem } from '@x/ecommerce/shop-client';
import { IGtmProduct } from '../models';
import { GtmEvent } from './gtm-event.event';

export interface IGtmBasketClickEvent {
  add?: {
    products: IGtmProduct[];
  };
  remove?: {
    products: IGtmProduct[];
  };
}

export class GtmBasketClickEvent extends GtmEvent {
  public ecommerce: IGtmBasketClickEvent;

  constructor(private flow: 'add' | 'remove') {
    super(flow === 'add' ? 'EEAddToBasket' : 'EERemoveFromBasket');
    this.ecommerce = { [flow]: { products: [] } };
  }

  public buildEvent(orderItems: IShopOrderItem[]): void {
    this.ecommerce[this.flow]!.products = orderItems.map((item) => this.orderItemToProduct(item));
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
