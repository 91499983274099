import { TrackingProvider } from '@x/common/tracking';
import { TEConversionPurchase, TrackingEvent } from '@x/common/tracking/event-models';
import { IFireworksConfig } from '@x/common/tracking/providers/fireworks/fireworks.config';
import { FireworksService } from '@x/common/tracking/providers/fireworks/fireworks.service';

export class FireworksProvider extends TrackingProvider {
  constructor(
    private config: IFireworksConfig,
    private fireworksService: FireworksService,
  ) {
    super();
  }

  async init(): Promise<boolean> {
    return await this.fireworksService.injectScripts(this.config.analyticsSrcUrl);
  }

  sendEvent<T extends object>(event: TrackingEvent<T>): void {
    try {
      if (event instanceof TEConversionPurchase) {
        const { order } = event.context.data;
        this.fireworksService.sendPurchaseEvent(order, order.items);
      }
    } catch (error) {
      console.error('Error while sending event to Fireworks', error);
    }
  }
}
