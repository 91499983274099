import { Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { CookieBotService } from '@x/common/cookie-bot/cookie-bot.service';
import { TrackingAmountPipe } from '@x/common/tracking/pipes/tracking-amount.pipe';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AlgoliaService {
  constructor(
    private windowRef: WindowRef,
    private cookieBotService: CookieBotService,
    private trackingAmountPipe: TrackingAmountPipe,
  ) {}

  get userToken$(): Observable<string> {
    return this.cookieConsent$.pipe(
      filter(Boolean),
      switchMap(() => this.createUserTokenObservable(300)),
    );
  }

  get cookieConsent$() {
    return this.cookieBotService.cookieConsent$;
  }

  convertCents(cents: number) {
    return this.trackingAmountPipe.transform(cents);
  }

  unrelatedToSearch(queryId: string | null | undefined) {
    return !Boolean(queryId);
  }

  private createUserTokenObservable(timeOut = 1000): Observable<string> {
    return new Observable<string>((observer) => {
      const intervalId = setInterval(() => {
        const browserCookie = this.getGoogleAnalyticsUserIdFromBrowserCookie('_ga');
        if (browserCookie) {
          observer.next(browserCookie);
          observer.complete();
          clearInterval(intervalId);
        }
      }, timeOut);

      // Cleanup interval on unsubscription
      return () => clearInterval(intervalId);
    });
  }

  private getGoogleAnalyticsUserIdFromBrowserCookie(cookieName: string) {
    const browserCookie = this.windowRef.getBrowserCookie(cookieName);

    if (!browserCookie) {
      return undefined;
    }

    return this.extractGoogleAnalyticsUserIdFromCookie(browserCookie);
  }

  private extractGoogleAnalyticsUserIdFromCookie(gaCookie: string) {
    if (gaCookie) {
      // Remove the Google Analytics tracker from the device ID.
      const userIdParts = gaCookie.split('.').slice(-2);
      if (userIdParts.length === 2) {
        return userIdParts.join('_');
      }
    }
    return undefined;
  }
}
