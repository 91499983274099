import { TrackingEventName } from '@x/common/tracking/tracking.types';

export abstract class TrackingEvent<T extends object> {
  abstract [TrackingEventName]: string;

  protected constructor(public context: T) {}

  protected static eventNameStartsWith(eventName: string, obj: any) {
    return typeof obj === 'object' && obj[TrackingEventName].indexOf(eventName) === 0;
  }
}
