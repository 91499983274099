import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName, TShopRouteOrigin } from '@x/common/tracking/tracking.types';

type TNavigationPageEventData = {
  title?: string;
  routeOrigin: TShopRouteOrigin;
  channel: string;
  locale: string;
  path: string;
  utmParams?: Record<string, string>;
};

type TNavigationEventArgs = {
  data: TNavigationPageEventData;
};

export abstract class NavigationEvent extends TrackingEvent<TNavigationEventArgs> {
  [TrackingEventName] = 'navigation';

  constructor(data: TNavigationPageEventData) {
    super({ data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('navigation', obj);
  }
}

export class TENavigationStart extends NavigationEvent {
  [TrackingEventName] = 'navigation:start';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('navigation:start', obj);
  }
}

export class TENavigationEnd extends NavigationEvent {
  [TrackingEventName] = 'navigation:end';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('navigation:end', obj);
  }
}
