import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { ITrackingShopContext, TRACKING_SHOP_CONTEXT } from '@x/common/tracking';
import { TrackingAmountPipe } from '@x/common/tracking/pipes/tracking-amount.pipe';
import { logMessageByColor } from '@x/common/tracking/tracking.functions';
import { IShopCheckoutOrder, IShopOrderItem } from '@x/ecommerce/shop-client';

@Injectable({ providedIn: 'root' })
export class FireworksService {
  private renderer: Renderer2;

  constructor(
    private windowRef: WindowRef,
    private rendererFactory: RendererFactory2,
    @Inject(TRACKING_SHOP_CONTEXT) private shopContext: ITrackingShopContext,
    private trackingAmountPipe: TrackingAmountPipe,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  sendPurchaseEvent(order: IShopCheckoutOrder, products: IShopOrderItem[]) {
    const product = products.map((i) => {
      return {
        ext_product_id: String(i.productId),
        price: i.unitPrice,
        quantity: i.quantity,
      };
    });

    const purchaseEvent = {
      order_id: order.id,
      order_value: this.trackingAmountPipe.transform(order.total),
      currency: order.currency,
      country: order.shippingAddress?.country ?? this.shopContext.defaultCountry.name,
      subtotal: this.trackingAmountPipe.transform(order.subtotal),
      product,
    };

    this.windowRef.get('_fwn')?.analytics?.purchase(purchaseEvent);

    console.debug('Fireworks Analytics: Purchase event sent', purchaseEvent);
  }

  async onPlayerProductsLoaded(): Promise<string[]> {
    return new Promise((resolve) => {
      this.windowRef.get('_fwn')?.shopping?.onProductsLoaded(({ products }: any) => {
        try {
          const productExtIds = products.map(
            (product: { product_ext_id: string }) => product.product_ext_id,
          );
          resolve(productExtIds);
        } catch (error) {
          resolve([]);
        }
      });
    });
  }

  async injectScripts(url: string): Promise<boolean> {
    try {
      const headerScript: HTMLScriptElement = this.windowRef.document.createElement('script');
      headerScript.type = 'text/javascript';
      headerScript.async = true;
      headerScript.src = url;

      this.renderer.appendChild(this.windowRef.document.head, headerScript);

      logMessageByColor('FireWorks Analytics: Initialized successfully!');

      return true;
    } catch (error) {
      console.error('Error initialising Fireworks Analytics', error);

      return false;
    }
  }
}
