export const TrackingEventName = Symbol('TrackingEventName');

export type TShopRouteOrigin =
  | 'My Account'
  | 'Checkout'
  | 'Taxon Listing'
  | 'Product Listing'
  | 'Product Detail'
  | 'Shopping Bag'
  | 'Landing Page'
  | 'Homepage'
  | 'Search Page'
  | 'Login'
  | 'Register'
  | 'Unknown';

export type TFormOrigin = 'Login' | 'Registration' | 'Address' | 'Region Overlay';

export type TAssociationOrigin =
  | 'checkout-recommendation'
  | 'add-to-bag-recommendation'
  | 'mini-bag-recommendation'
  | 'product-detail-recommendation';
