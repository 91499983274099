import { IShopProductItem, IShopProductVariant } from '@x/ecommerce/shop-client';
import { IGtmAction, IGtmProduct } from '../models';
import { GtmEvent } from './gtm-event.event';

export interface IGtmClickEvent {
  click: {
    actionField: IGtmAction;
    products: IGtmProduct[];
  };
}

export class GtmProductClickEvent extends GtmEvent {
  public ecommerce: IGtmClickEvent;

  constructor(list: string) {
    super('EEClick');
    this.ecommerce = { click: { actionField: { list }, products: [] } };
  }

  public buildEvent(product: IShopProductItem, variant?: IShopProductVariant): void {
    this.ecommerce.click.products = [this.convertToProduct(product, variant)];
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
