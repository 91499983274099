import {
  TEAuthLogin,
  TECartUpdateItem,
  TEClickProductItem,
  TEConversionPurchase,
  TEWishlistAddItem,
  TrackingEvent,
} from '@x/common/tracking/event-models';
import { IAlgoliaProviderConfig } from '@x/common/tracking/providers/algolia/algolia.config';
import { AlgoliaService } from '@x/common/tracking/providers/algolia/algolia.service';
import { logMessageByColor } from '@x/common/tracking/tracking.functions';
import { TrackingProvider } from '@x/common/tracking/trackingProvider';
import aa, { InsightsEventObjectData } from 'search-insights';

export class AlgoliaProvider extends TrackingProvider {
  constructor(
    private config: IAlgoliaProviderConfig,
    private algoliaService: AlgoliaService,
  ) {
    super();

    this.algoliaService.userToken$.subscribe((userToken) => {
      aa('setUserToken', userToken);
      console.debug('Algolia Insights: User token set!', userToken);
    });
  }

  sendEvent(event: TrackingEvent<any>): void {
    try {
      if (event instanceof TEAuthLogin) {
        aa('setAuthenticatedUserToken', `user-${event.context.data.user.id}`);
      }

      if (event instanceof TEClickProductItem) {
        const {
          data: {
            productItem: {
              searchContext: { queryId, objectId, position, queryIndex },
            },
          },
        } = event.context;

        if (this.algoliaService.unrelatedToSearch(queryId)) {
          if (!objectId || !queryIndex) return;
          aa('clickedObjectIDs', {
            eventName: 'Products Clicked',
            objectIDs: [objectId],
            index: queryIndex,
          });

          return;
        }

        if (!position || !queryId || !objectId || !queryIndex) return;
        aa('clickedObjectIDsAfterSearch', {
          eventName: 'Products Clicked',
          queryID: queryId,
          positions: [position],
          objectIDs: [objectId],
          index: queryIndex,
        });
      }

      if (event instanceof TECartUpdateItem) {
        const {
          routeOrigin,
          data: { items, currency },
        } = event.context;

        if (!items[0].context) return;

        const { queryId, objectId, queryIndex } = items[0].context;

        const objectData: InsightsEventObjectData[] = items.map((item) => {
          return {
            price: this.algoliaService.convertCents(item.unitPrice),
            quantity: item.quantity,
          };
        });

        if (this.algoliaService.unrelatedToSearch(queryId)) {
          if (!objectId || !queryIndex) return;
          aa('addedToCartObjectIDs', {
            eventName: 'Product Added To Cart',
            objectIDs: [objectId],
            index: queryIndex,
            currency: currency ?? undefined,
            objectData,
          });
          return;
        }

        if (!queryIndex || !queryId || !objectId) return;

        switch (routeOrigin) {
          case 'Search Page': {
            aa('addedToCartObjectIDsAfterSearch', {
              index: queryIndex,
              queryID: queryId,
              objectIDs: [objectId],
              eventName: 'Product Added To Cart',
              currency: currency ?? undefined,
              objectData,
            });
            break;
          }
          case 'Product Detail': {
            aa('convertedObjectIDsAfterSearch', {
              index: queryIndex,
              queryID: queryId,
              objectIDs: [objectId],
              eventName: 'Product Added To Cart',
              currency: currency ?? undefined,
              objectData,
            });
          }
        }
      }

      if (event instanceof TEWishlistAddItem) {
        const {
          data: { context },
          routeOrigin,
        } = event.context;

        if (!context) return;

        const { objectId, position, queryId, queryIndex } = context;

        if (this.algoliaService.unrelatedToSearch(queryId)) {
          if (!objectId || !queryIndex) return;

          aa('convertedObjectIDs', {
            index: queryIndex,
            eventName: 'Add To Wishlist',
            objectIDs: [objectId],
          });

          return;
        }

        if (!queryId || !position || !objectId || !queryIndex) return;

        switch (routeOrigin) {
          case 'Search Page': {
            aa('clickedObjectIDsAfterSearch', {
              eventName: 'Add To Wishlist',
              queryID: queryId,
              positions: [position],
              objectIDs: [objectId],
              index: queryIndex,
            });
            break;
          }
          case 'Product Detail': {
            aa('convertedObjectIDsAfterSearch', {
              index: queryIndex,
              queryID: queryId,
              objectIDs: [objectId],
              eventName: 'Add To Wishlist',
            });
          }
        }
      }

      if (event instanceof TEConversionPurchase) {
        const {
          data: { order },
        } = event.context;

        const unrelatedItems = order.items
          .filter((item) => !Boolean(item.context?.queryId))
          .filter((item) => Boolean(item.context?.objectId && item.context?.queryIndex));

        if (unrelatedItems.length) {
          aa('purchasedObjectIDs', {
            index: unrelatedItems[0].context!.queryIndex!,
            eventName: 'Products Purchased',
            currency: order.currency ?? undefined,
            objectIDs: unrelatedItems.map((item) => item.context!.objectId!),
            objectData: unrelatedItems.map((item) => {
              return {
                price: this.algoliaService.convertCents(item.unitPrice),
                quantity: item.quantity,
              };
            }),
          });
        }

        const items = order.items.filter((item) =>
          Boolean(item.context?.objectId && item.context?.queryIndex && item.context?.queryId),
        );

        if (!items.length) return;

        const objectIDs = items.map((item) => item.context!.objectId!);
        const index = items[0].context!.queryIndex!;
        const objectData = items.map((item) => {
          return {
            queryID: item.context!.queryId!,
            price: this.algoliaService.convertCents(item.unitPrice),
            quantity: item.quantity,
          };
        });

        aa('purchasedObjectIDsAfterSearch', {
          eventName: 'Products Purchased After Search',
          index,
          objectIDs,
          currency: order.currency ?? undefined,
          objectData,
        });
      }
    } catch (e) {
      console.error('Error sending event to Algolia Insights', e);
    }
  }

  async init(): Promise<boolean> {
    try {
      const { appId, apiKey } = this.config;

      aa('init', {
        appId,
        apiKey,
      });
      logMessageByColor('Algolia Insights: Initialized successfully!');

      return true;
    } catch (e) {
      console.error('Error initialising Algolia Insights', e);
      return false;
    }
  }
}
