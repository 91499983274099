/*
 * To avoid passing the shop context to every event, we can use a singleton pattern to store the shop context.
 * */

import { ITrackingShopContext } from '@x/common/tracking/tracking.module.config';

export class GTMEventShopContext {
  private static instance: GTMEventShopContext;
  private context: ITrackingShopContext;

  static initialize(context: ITrackingShopContext) {
    if (!GTMEventShopContext.instance) {
      GTMEventShopContext.instance = new GTMEventShopContext();
      GTMEventShopContext.instance.context = context;
    }
  }

  static getInstance(): GTMEventShopContext {
    if (!GTMEventShopContext.instance) {
      throw new Error(
        'Shop Context for GTM Events has not been initialized. Call initialize(context) first.',
      );
    }
    return GTMEventShopContext.instance;
  }

  getContext() {
    return this.context;
  }
}
