import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trackingAmount',
  standalone: true,
})
export class TrackingAmountPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(cents: number, removeNegative: boolean = false): string | undefined {
    const centsConverted = removeNegative ? Math.abs(cents / 100) : cents / 100;
    const amount = this.decimalPipe.transform(centsConverted, '1.2', 'en-US');
    return amount ?? undefined;
  }
}
