import { IGtmProduct } from '@x/common/tracking/providers/google-tag-manager/models';
import { IShopProductItem, IShopProductVariant } from '@x/ecommerce/shop-client';
import { GtmEvent } from './gtm-event.event';

export interface IGtmDetailEvent {
  detail: {
    products: IGtmProduct[];
  };
}

export class GtmProductDetailEvent extends GtmEvent {
  public ecommerce: IGtmDetailEvent;

  constructor() {
    super('EEDetail');
    this.ecommerce = { detail: { products: [] } };
  }

  public buildEvent(product: IShopProductItem, variant?: IShopProductVariant): void {
    this.ecommerce.detail.products = [this.convertToProduct(product, variant)];
  }

  public toEvent() {
    const baseData = super.toEvent();
    return {
      ...baseData,
      ecommerce: this.ecommerce,
    };
  }
}
