import { TrackingEvent } from '@x/common/tracking/event-models/event';
import { TrackingEventName, TShopRouteOrigin } from '@x/common/tracking/tracking.types';

type TDialogIntent = 'Update Address' | 'Add Address' | 'Prompt' | 'Error' | 'Success';
type TDialogType = 'Address' | 'Region Overlay';
type TDialogResponse = 'Positive' | 'Dismissed';

type TDialogEventArgs<Data> = {
  shopOrigin: TShopRouteOrigin;
  data: Data;
};

type TDialogBaseEventData = {
  dialogIntent: TDialogIntent;
  dialogType: TDialogType;
};

export abstract class DialogEvent<Data extends object> extends TrackingEvent<
  TDialogEventArgs<Data & TDialogBaseEventData>
> {
  constructor(shopOrigin: TShopRouteOrigin, data: Data & TDialogBaseEventData) {
    super({ shopOrigin: shopOrigin, data });
  }

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('dialog:', obj);
  }
}

export class TEDialogOpen<Data extends object> extends DialogEvent<Data> {
  [TrackingEventName] = 'dialog:open';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('dialog:open', obj);
  }
}

type TDialogCloseEventData = {
  dialogResponse: TDialogResponse;
};
export class TEDialogClose extends DialogEvent<TDialogCloseEventData> {
  [TrackingEventName] = 'dialog:close';

  static [Symbol.hasInstance](obj: any) {
    return this.eventNameStartsWith('dialog:close', obj);
  }
}
